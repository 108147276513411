.ce_rsce_trenner {width: 80%;margin: 0 auto;max-width: 1200px;display: flex;margin-top: -75px;margin-bottom: 75px;}

.ce_rsce_trenner {

    .trenner-line{
        width: 55%;
        height: 1px;
        background-color: $primary;
        
    }
    
}
.ce_rsce_trenner.left {
    justify-content: start;
}
.ce_rsce_trenner.right {
    justify-content: end;
}
.ce_rsce_produkte {

    ul {padding: 0;line-height: 1.7rem;}
    li {list-style: square;}
    
    .inside {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-bottom: 3.1rem;
        gap: 2rem;

        .text-container, .image-container {
            width: 100%;
        }

        .text-container {
            order: 1;
            border-bottom: 1px solid #82b240;

            .button {
                color: $dark;
                text-decoration: none;
                display: flex;
                align-items: center;
                transition: 0.7s color;

                img {
                    width: 2.5rem;
                    margin-right: .9rem;
                    transition: .6s;
                }
            }
            .button:hover {
                color: $primary;
            }
            .button:hover img {
                transform: translateX(10px);
            }
            

            h2 {
                color: $dark;
                font-size: 1.6em;
                margin: 0 0 .6rem 0;
                line-height: 2rem;
                font-weight: bold;
            }

            .zusatztext p {
                font-size: 0.75em;
            }
        }
        .image-container {
            
            order: 0;
            position: relative;

            img {
                transition: 0.7s;
                height: 100%;
                object-fit: cover;
                width: fit-content;
            }
            img:hover {
                transform: scale(1.02);
            }

            .pricelabel-container {
                display: flex;
                gap: 1rem;
                position: relative;
                top: unset; 
                right: unset; 
                justify-content: space-between;
                margin-top: 1.2rem;
                margin-bottom: 1.2rem;
                flex-direction: column;

                .pricelabel {
                    padding: .9rem;
                    border-radius: 3.1rem;
                    color: white;
                    margin: 0;
                    font-weight: bold;
                   
                }

                .neupreis {
                   text-align: center;
                    background-color: $dark;
                }
                .sonderpreis {
                   text-align: center;
                    background-color: $primary;
                   
                }
                
            }

        }
    }
}




@mixin rsce_produkte-mobile_l{
    .ce_rsce_produkte .inside .image-container .pricelabel-container {flex-direction: row;}
}





@mixin rsce_produkte-tablet{
    .ce_rsce_produkte {
        margin-bottom: 9.3rem;
    }
    .ce_rsce_produkte .inside {
        flex-direction: row;
        
    
        .text-container, .image-container {
            width: 50%;
        }

        .image-container {

            a {
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        
    
    }

    .ce_rsce_produkte.arrangement-text-left {
        .image-container .pricelabel-container { justify-content: start;position: absolute;bottom: -1.5rem;left: -2.5rem;margin: 0;}
        .text-container {order:0; padding-right: 3rem;}
        .image-container{order:1}
        
    }
    .ce_rsce_produkte.arrangement-text-right {
        .image-container .pricelabel-container { justify-content: end;position: absolute;bottom: -1.5rem;right: -2.5rem;margin: 0;}
        .text-container {order:1; padding-left: 3rem;}
        .image-container{order:0}
       
    }


}









#nav {
    display: flex;
    list-style: none;
    
    a {
        color: $dark;
    }  
    .nav-submenu {
        padding: 0;
        li {list-style: none;}
    } 
    
    .nav-submenu.level_2 {
        display: none;
    }
}

header {position: sticky;top:0; z-index: 9;}
header .inside {
    width: auto;
    max-width: unset;
    margin-bottom: 0px;

    .top::before {
        content: " ";
        width: 40%;
        background-color: $dark;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
    }
    .top {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        z-index: 1000;
        background-color: white;
        padding: 2.5rem 10% 2.5rem;
        transition: padding .3s;
        flex-wrap: wrap;

        &.scrolled {
            padding: 1.5rem 10% 1.5rem;
            
        }



        .left {
            padding-top: .30em;
            flex: 100%;
            a {display: flex;justify-content: center;}
            img {
                width: 60%;
                min-width: 150px;
                max-width: 250px;
            }
        }

        .right {
            display: flex;
            justify-content: space-between;
            flex: 100%;
            margin-top: 2rem;
            .icons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 80%;
                img {
                    width: 1rem;
                    height: 1rem;
                    
                }
            }
            .nav-mobile{
                flex: 20%;
                display: flex;
                justify-content: end;
                .nav-view .icons {

                    display: flex;
                    justify-content: space-evenly!important;
                    align-items: center;
                
                    border-top: 1px solid $light;
                    padding-top: 1.25rem;
                
                    a {
                        img {
                            width: 20px;
                            height: 20px;
                            filter: invert(29%) sepia(10%) saturate(3760%) hue-rotate(39deg) brightness(106%) contrast(50%);
                        }
                        
                    }
                
                }
            
            
            
            }
            .toggle-menu {
                position: relative;
                transition: .3s;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                border-radius: 0.57143rem;
                background-color: rgba(0,0,0,0);
                border: 0;
                outline: 0;
                right: 0%;
                z-index: 101;
                padding: 0;
            }
            .toggle-menu.close {
                position: absolute;
                cursor: pointer;
                top: 3rem;
                right: 3rem;
                
            }
           .lines, .lines::before, .lines::after {    
                display: inline-block;
                content: " ";
                width: 2.5rem;
                height: 2px;
                background: $primary;
                border-radius: 0.28571rem;
                transition: .3s;
                position: relative;
            }
            .lines::before {
                top:1rem
            }
            .lines::after {
                top: -1rem;
            }
            .toggle-menu.close .lines {
                background: 0 0;
            }
            
            .toggle-menu.close .lines::before,
            .toggle-menu.close .lines::after {
                position: absolute;
                height: 2px;
                width: 4rem;
                
                transform-origin: center;
            }
            
            .toggle-menu.close .lines::before {
                top: .5rem;
                left: 0px;
                transform: rotate(-45deg);
            }
            
            .toggle-menu.close .lines::after {
                top: .5rem;
                left: 0px;
                transform: rotate(45deg);
            }
        }

        #nav li {
            margin-left: 1.25rem;

            a {
                text-decoration: none;
                position: relative;
                transition: color .6s;
            }
            a:hover {
                color: $primary;
            }
            a:before {
                content: "";
                position: absolute;
                top: -6.25rem;
                left: 50%;
                width: 2px;
                height: 4.6rem;
                background-color: $primary;
                transform: scaleY(0);
                transform-origin: bottom;
                transition: transform 0.3s ease-in-out;
              }
              
              a:hover:before {
                transform: scaleY(1);
                transform-origin: top;
                transition: transform 0.3s ease-in-out;
              }
              
              a.active:before {
                transform: scaleY(1);
                transform-origin: top;
              }   
        }

        #nav .nav-item.active > a{
            color: $primary;
        }

        #nav .nav-submenu-item.active a{
            color: $primary;
        }
       
       

        #nav .active > a:before {
            transform: scaleY(1);
            transform-origin: top;
        }

    }

}





.nav-mobile {display: block;}
.mod_navigation.block {display: none;}
.nav-view .mod_navigation.block {display: block;}

header .inside .top::before, header .inside .bottom::before {
    width: 100%;
}

header .inside .bottom .slideshow {
    grid-template-columns: 1fr;
}

header .inside .bottom .slideshow .slider-text {
    justify-content: center;
    margin-left: 0;
}

.nav-view.active {
    right: 0;
}
    
.nav-view {
    width: 75%;
    max-width: 18.75rem;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    padding-top: 6rem;
    background-color: white;
    z-index: 100;
    transition: .6s;
    box-shadow: -1px 0px 16px -3px #000000;

    .mod_navigation.block {

        #nav {
            flex-direction: column;

            li {
                margin: 0 0 1.25rem 0;

                a:hover {
                    color: $primary;
                }
            }

            a::before {
                top: unset;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 4.6rem;
            }
            a.active:before {
                transform: scaleY(1);
                transform-origin: top;
            }
            
            .nav-item.submenu {
                position: relative;
                transition: .5s;
               
            }
            .nav-item.nav-submenu ul {
                opacity: 0;
                transition: .5s;
            }
            .nav-click:hover {
                cursor: pointer;
            }
            .nav-click:after {
                content: "+";
                position: absolute;
                width: 10px;
                height: 10px;
                font-size: 26px;
                color: $primary;
                top: -3px;
                right: 50px;
            }

            .nav-item.submenu.on > ul {
                display: block;
                position: relative;
                opacity: 1;
            }
            .nav-item.submenu.on .nav-click:after {
                content: "-";
            }



        }

    }
        
}


@mixin rsce_nav-mobile{
    header .inside .top .left img {width: 100%;}
}




@mixin rsce_nav-tablet_l{
    
    .nav-mobile {display: none;}
    .mod_navigation.block {display: block;}
    .nav-view .mod_navigation.block {display: none;}

    header .inside .top::before, header .inside .bottom::before {
        width: 40%;
    }

    header .inside .top {
        padding: 2.5rem 10% 3.1rem;
        align-items: center;
        flex-wrap: nowrap;
    
        .left {
            padding-top: 0;
            width: 100%;

            a {justify-content: left;}
            
            img {
                width: 100%;
                max-width: 21.25rem;
            }
        }

        .right {
            .nav-mobile {display: none;}
        }
        &.scrolled {
            padding: 1rem 10% 1rem;
            
        }

      

            ul#nav li:hover ul li {
                opacity: 1;
                
            }
            ul#nav li:hover ul {
                transition: .3s;
                display: block;
            }

            #nav {
            .nav-item {position: relative;}
            .nav-submenu.level_2 {position: absolute;background-color: white;padding: 15px;margin-top: 0;left: -15px;width: 200px;}
            .nav-submenu-item {margin-left: 0;opacity: 0;}
            .nav-submenu.level_2 li a:before {content: unset;}
            }

            

        
    
    
    }
    header .inside .bottom .slideshow {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 0.4fr;
        margin: 0 10% 0 10%;
    }
    header .inside .bottom .slideshow .slider-text {
        margin-left: 3.1rem;
    }

}


@mixin rsce_nav_desktop{
    #nav {gap: 2rem;}
}





.ce_rsce_referenzen {width: 80%;margin: 0 auto 150px;}

.ce_rsce_referenzen .inside {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
    


    h2 {
      color: $light;
    }
    .refernzen-container {
        display: flex;
        transition: transform 0.5s ease-in-out;
        

        .referenz-slide {
            flex-shrink: 0;
            width: 100%;
            text-align: center;
           
          
            .referenz-slide p {
                font-size: 1rem;
                margin-top: .6rem;
              
            
            
            }
        }
      }
      .ref-prev, .ref-next {
        position: absolute;
        bottom: -30px;
        transform: translateY(-50%);
        font-size: 1.8rem;
        border: none;
        cursor: pointer;
        width: 6.25rem;
        background-color: transparent;
      }
      
      .ref-prev {
        display: flex;
        justify-content: flex-start;
        left: 0;
        

        img {
          width: 50%;
          min-width: 50px;
          transform: rotate(180deg);
        }
      }
      
      .ref-next {
        display: flex;
        justify-content: end;
        right: 0;
        
        img {
          width: 50%;
          min-width: 50px;
        }
      }

    
}
  
  
  
  


@mixin rsce_referenzen-tablet{
  
  
  .ce_rsce_referenzen .inside {
   
    .refernzen-container {
      display: flex;
      transition: transform 0.5s ease-in-out;
      

      .referenz-slide {
        padding: 0 20%;
      }
      .ref-prev, .ref-next {
        top: 65%;
      }
    }
     
      
      .ref-prev {
         bottom: unset;
         top: 75%;

        img {
          width: 100%;
          transition: .6s;
          transform: rotate(180deg);
        }
      }
      
      .ref-next {
        bottom: unset;
         top:75%;
        
        img {
          width: 100%;
          transition: .6s;
        }
      }


      

    
}

}
  
 
  
  
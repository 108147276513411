.lb-bg{
    background-color: rgba(0, 0, 0, .8);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
    backdrop-filter: unset;
    transition: opacity linear .1s, backdrop-filter linear .5s;
    opacity: 0;
}
.lb{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 200px;
    min-width: 250px;
    z-index: 20;
    background-color: rgba(0, 0, 0, 1);
    transition: opacity linear .1s;
    opacity: 0;
    box-shadow: 0 0 50px #000;
    & .lb-img-cnt{
        overflow: hidden;
    }
    & img{
        height: auto;
        max-height: calc(95vh - 6rem);
        width: auto;
        max-width: 95vw;
        object-fit: contain;
        transition: transform cubic-bezier(0,0,.22,.99) .1s, opacity linear .1s;
    }
    & .lb-close{
        position: fixed;
        top: 0;
        right: 0;
        padding-left: 15px;
        padding-right: 15px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .7);
        border-bottom-left-radius: 3px;
        z-index: 5;
        &::after{
            color: white;
            content: $close;
            font-family: $iconFont;
            font-size: 2.25rem;
        }
    }
    & .lb-prev{
        display: inline-block;
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        padding: 10px 10px 10px 5px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .7);
        z-index: 5;
        &::after{
            display: inline-block;
            color: white;
            content: $chevronRight;
            font-family: $iconFont;
            font-size: 2rem;
            transform: rotateZ(180deg);
        }
    }
    & .lb-next{
        display: inline-block;
        position: fixed;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 10px 5px 10px 10px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .7);
        z-index: 5;
        &::after{
            display: inline-block;
            color: white;
            content: $chevronRight;
            font-family: $iconFont;
            font-size: 2rem;
        }
    }
}
.lb-load-bg{
    opacity: 1;
    background-color: rgba(0, 0, 0, .75);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity linear .1s;
    .lb-load{
        background: none !important;
        position: absolute; 
        top: 50% !important;
        left: 50% !important;
        border-bottom: 5px solid $primary;
        border-top: 5px solid $primary;
        border-radius: 50%;
        width: 100px !important;
        height: 100px !important;
        animation: spinning 1s linear 0s infinite;
    }
}
@keyframes spinning{
    0%{
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

.ce_rsce_footer {margin-top: 150px;}
.ce_rsce_footer .inside {
    margin-bottom: 0;
}

.prefooter-container {
    
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;

    .pre-footer {
        position: relative;
    }

    .pre-footer::after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        left: -25%;
        top: -15%;
        background-color: $dark;
        z-index: -1;
    }

    .prefooter-link {
        display: flex;
        align-items: flex-end;
        justify-content: start;

        a {
            color: $light;
            text-decoration: none;
            display: flex;
            align-items: end;
            flex-direction: column;
            font-size: 3em;
            font-weight: 300;
            transition: color .7s;
            line-height: 3.5rem;
        }
        a:hover {
            color: $primary;
        }
        a:hover:after {
            transform: translateX(10px);
        }
        h2 {
            color: $light;
            display: flex;
            flex-direction: column;
            align-items: start;
            font-size: 3em;
            margin: 0;
        }
        .trenner {
            width: 50%;
            height: 1px;
            background-color: $primary;
            margin: 1.8rem 0 1.8rem 0;
        }
        a::after {
            content: " ";
            display: block;
            width: 6.25rem;
            height: .938rem;
            background-color: transparent;
            background-image: url('/files/themes/daswohnteam/images/Pfeil.svg');
            background-repeat: no-repeat;
            transition: .6s;
        }
    }

}




nav.mod_customnav.block {
    padding: 1.5rem 10%;

    .level_1 {
        display: flex;
        padding: 0;

        li {
            list-style: none;
            margin-right: 20px;
            
            a {
                color: $light;
                text-decoration: none;
                transition: color .7s;
            }
            a:hover {
                color: $primary;
            }
           
        }
    }
}




@mixin rsce_footer-mobile{
    .prefooter-container .prefooter-link a {flex-direction: row;width: 100%;justify-content: space-between;}
}



@mixin rsce_footer-tablet{
    
    .ce_rsce_footer .inside {
        max-width: unset;
    }
   
    .prefooter-container {
        grid-template-columns: 1.2fr 0.8fr;
    }


}


@mixin rsce_footer-desktop {
    .prefooter-container .prefooter-link a {
        width: 70%;
        max-width: 420px;
    }

}
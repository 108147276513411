@font-face {
  font-family: 'TitilliumWeb';
  src: url('/files/themes/daswohnteam/fonts/TitilliumWeb-Light.ttf') format('truetype'),
       url('/files/themes/daswohnteam/fonts/TitilliumWeb-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: 'TitilliumWeb';
  src: url('/files/themes/daswohnteam/fonts/TitilliumWeb-Regular.ttf') format('truetype'),
       url('/files/themes/daswohnteam/fonts/TitilliumWeb-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* SemiBold */
@font-face {
  font-family: 'TitilliumWeb';
  src: url('/files/themes/daswohnteam/fonts/TitilliumWeb-SemiBold.ttf') format('truetype'),
       url('/files/themes/daswohnteam/fonts/TitilliumWeb-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

.ce_rsce_produktvorstellung {

    .produkt-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap:3rem;

        img {
            transition: 0.7s;
        }
        img:hover {
            transform: scale(1.02);
        }

        a {color: $light}
        a:hover {text-decoration: none;}

        .produkt-title {
            display: flex;
            align-items: baseline;

            &::before {
                position: relative;
                content: " ";
                width: 4.6rem;
                top: 0px;
                left: 0px;
                display: block;
                height: 1.3rem;
                border-bottom: 1px solid $primary;
                margin-right: .9rem;

            }
        }
    }
}

@mixin rsce_produktvorstellung-tablet{
    .ce_rsce_produktvorstellung .produkt-container {grid-template-columns: 1fr 1fr;}

}










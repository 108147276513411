
.gallery-container {

    .flex-equal {

        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .flex-big-left {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex-direction: column;   

        a {
            flex: 3;
            
        }
    }

    .flex-small {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex: 1;

        a {
            
            img {
                height: 100%;
                object-fit: cover;
                width: fit-content;
            }
        }
       
    }

    .flex-big-right {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex: 3;
        flex-direction: column;

        a {
            
        }
    }

    .flex-big-left a {
        width: 100%;
    }

    .flex-big-right a {
        flex: 3;
    }

}

@mixin rsce_gallerie-tablet{
   
    .gallery-container {

        .flex-equal {
            display: flex;
            gap: 3rem;
            margin-bottom: 3rem;
        }

        .flex-big-left {
            display: flex;
            gap: 3rem;
            margin-bottom: 3rem;
            flex-direction: row;
            height: 100%;
            
            a {
                flex: 2;
                
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }


        .flex-small {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            flex: 1;
        }

        .flex-big-right {
            display: flex;
            gap: 3rem;
            margin-bottom: 3rem;
            flex: 3;
            flex-direction: row;
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .flex-big-left a {
            width: 100%;
        }

        .flex-big-right a {
            flex: 2;
        }

    }

}



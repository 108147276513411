body {
	margin: 0; // 1
	font-family: $theme-font-family, $font-family-base;
	font-weight: $fwRegular;
	line-height: $mainLineHeight;
	color: $fontColor;
	text-align: left;
	background-color: transparent; // 2
	-webkit-text-size-adjust: 100%; // 3
	-webkit-tap-highlight-color: rgba($black, 0); // 4
	letter-spacing: 0.01em;
	word-spacing: 0.01em;
	hyphens: none;
}

a{
    color: $link-color;
    text-decoration: $link-decoration;
    &:hover{
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

h1, h2, h3, h4, h5, h6 {
	font-weight: $fwBold;
	color: $headings-color;
	hyphens: none;
}

h1 {
	@include scaleText($size: msInt(5), $linesAbove: 6, $linesBelow: 4);
	font-size: 3.1rem;
	hyphens: auto;
}
h2 {
	@include scaleText($size: msInt(4), $linesAbove: 4, $linesBelow: 2);
	font-weight: 300;
	margin-bottom: 3rem;
}
h3 {
	@include scaleText($size: msInt(3), $linesAbove: 4, $linesBelow: 2);
}
h4 {
	@include scaleText($size: msInt(2), $linesAbove: 2, $linesBelow: 2);
}
h5 {
	@include scaleText($size: msInt(2), $linesAbove: 2, $linesBelow: 2);
}
h6 {
	@include scaleText($size: msInt(1), $linesAbove: 2, $linesBelow: 2);
}
p,
table,
ul,
ol,
blockquote,
figure {
	@include scaleText($size: 1, $linesAbove: 2, $linesBelow: 2);
	margin-top: 1rem;
    margin-bottom: 1rem;

}
small {
	@include scaleText($size: msInt(-1), $linesAbove: 1, $linesBelow: 1);
}
/*! Fluid Type */
@include fluid-type($minFontSize, $maxFontSize, $minFontWidth, $maxFontWidth);



@mixin heading_tablet {
	h1 {@include scaleText($size: msInt(6), $linesAbove: 6, $linesBelow: 4);}

	h2 {
		@include scaleText($size: msInt(5), $linesAbove: 4, $linesBelow: 2);
	}
	h3 {
		@include scaleText($size: msInt(4), $linesAbove: 4, $linesBelow: 2);
	}
	h4 {
		@include scaleText($size: msInt(3), $linesAbove: 2, $linesBelow: 2);
	}
	h5 {
		@include scaleText($size: msInt(2), $linesAbove: 2, $linesBelow: 2);
	}
	h6 {
		@include scaleText($size: msInt(1), $linesAbove: 2, $linesBelow: 2);
	}
}
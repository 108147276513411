.ce_text.block {
    max-width: 80%; 
    margin: auto;
    text-align: center;
    margin-bottom: 75px;

    h2 {color: $light;}
}

@mixin ce_text-tablet{
    .ce_text.block {
        
    }
}




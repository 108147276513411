/*! --Layout-- */
body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
	-webkit-font-smoothing: antialiased;
}
#footer {
	margin-top: auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
}




.inside {
	width: 80%;margin: 0 auto 75px;
	max-width: 1200px;
}

button {
	padding: 15px;
	border: 1px solid $light;
	transition: .3s ease-in-out;
	background-color: transparent;
	color: $dark;
}
button:hover {
	color: $white;
	border-color: $primary;
	background-color: $primary;
	cursor: pointer;
}



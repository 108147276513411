


.ce_rsce_slideshow {
    position: relative;
    
    .icons {
        position: absolute;
        left: .9rem;
        height: 100%;
        z-index: 8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: none;
    
        a {
            margin-bottom: 1.8rem; 
            transition: filter 0.7s;
        }
        a:last-of-type {
            margin-bottom: 0;
        }
        a:hover {
            filter: invert(29%) sepia(10%) saturate(3760%) hue-rotate(39deg) brightness(106%) contrast(50%);
        }
        img {
            width: .9rem;
            height: 1.25rem;
        }
        
    }
    .slider-counter::before {
        position: relative;
        content: " ";
        width: 4.6rem;
        top: 0;
        left: 0;
        display: block;
        height: 1.3rem;
        border-bottom: 1px solid $primary;
        margin-right: .9rem;
    }
    .slider-counter {
        position: absolute;
        right: 10%;
        top: 100%;
        display: flex;
        align-items: baseline;
        z-index: 1;


        .slider-count {
            padding: .3rem;
            transition: .7s;
        }
        .slider-count:hover {
            cursor: pointer;
            color: $primary;
        }


        .active p {
            font-size: 1.5rem;
        }
    }

    .slider-text {
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        max-width: 80%;
        margin: auto auto;
        margin-top: 5rem;

        .slidertext {
            position: relative;
            left: 0%;
            bottom: 15px;
            transition: opacity 0.7s ease-in-out;
            color: $light;
            font-size: 3rem;
            line-height: 4rem;
            margin: 0;
        }
    }
    .slider-text:after {
        content: " ";
        width: 55%;
        height: 1px;
        background-color: #82b240;
        position: absolute;
        bottom: -1rem;
    }
    .slider-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
               
    
        .sliderimg, img.sliderimg.first{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 0.7s ease-in-out;
            object-fit: cover;
            opacity: 0;
        }
        img.sliderimg.first {
            opacity: 1;
        }
    
    }
   
   
}




.map {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #f5f5f5;
    background-image: url("/files/themes/daswohnteam/images/google-maps-hintergrund.png");
    background-size: cover;
    z-index: 5;
}
.datenschutz-hinweis {
    text-align: center;
}




@mixin rsce_slideshow-tablet {
    .ce_rsce_slideshow{
        .icons {
            display: none;
        }
    }
}




@mixin rsce_slideshow-tablet_s{

    .ce_rsce_slideshow{
        .icons {
            left: 1.5rem;
            display: flex;
        }
        .slider-text {
            position: absolute;

            .slidertext {
                top: 25%;
                left: 60%;
                font-size: 3rem;
                text-shadow: unset;
            }
        }
        .slider-text:after {
            content: unset;
        }
    }


    .ce_rsce_slideshow:before {
        content: " ";
        width: 40%;
        background-color: $dark;
        position: absolute;
        left: 0;
        top: 0;
        height: 115%;
        z-index: -1;
    }
    .ce_rsce_slideshow {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 0.4fr;
        padding: 0 10%;
        margin-bottom: 15.5rem;


        .icons {
            left: 4%;
        }
        .slider-counter {
            right: 0;
            bottom: unset;
            top: 100%;
        }

        .slider-container {
            height: 100%;
            width: 100%;
            aspect-ratio: 3 / 2;

        }
        .slider-container:after {
            background-color: unset;
        }
        .slider-text {
            display: flex;
            align-items: center;
            width: 20%;
            left: unset;
            right: 0;
            margin-right: 10%;

            .slidertext {
                top: unset;
                left: unset;
                bottom: unset;
                font-size: 3rem;
                right: 0;
                color: $light;
            }
        }
    }

   
    
}


.gallery-container {


    .flex-big-left.geteilt {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex-direction: column;  
        position: relative; 
        margin-bottom: 5rem;

        a {
            flex: 3;
            
        }
    }

   

    .flex-small {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex: 1;
        order: 2;

        a {
            
            img {
                height: 100%;
                object-fit: cover;
                width: fit-content;
            }
        }
       
    }

    .flex-big-right.geteilt {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex: 3;
        flex-direction: column;
        position: relative; 
        margin-bottom: 5rem;
    }

    

    .flex-big-left a {
        width: 100%;
    }

    .flex-big-right a {
        flex: 3;
    }

    .flex-big-left.geteilt:first-of-type:before {content: unset;}
    .flex-big-left.geteilt::before{content: " "; width: 55%;height: 1px; background-color: $primary;position: absolute;top: -2.5rem;right: 0;}
    .flex-big-right.geteilt::before{content: " "; width: 55%;height: 1px; background-color: $primary;position: absolute;top: -2.5rem;}

}

@mixin rsce_gallerie_geteilt-tablet{
   
    .gallery-container {

       

        .flex-big-left.geteilt {
            display: flex;
            gap: 3rem;
            margin-bottom: 10rem;
            flex-direction: row;
            height: 100%;
            
            a {
                flex: 2;
                
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }


        .flex-small {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            flex: 1;
        }

        .flex-big-right.geteilt {
            display: flex;
            gap: 3rem;
            margin-bottom: 10rem;
            flex: 3;
            flex-direction: row;
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
        .flex-big-left.geteilt:first-of-type:before {content: unset;}
        .flex-big-left.geteilt::before{content: " "; width: 55%;height: 1px; background-color: $primary;position: absolute;top: -5rem;right: 0;}
        .flex-big-right.geteilt::before{content: " "; width: 55%;height: 1px; background-color: $primary;position: absolute;top: -5rem;}

        .flex-big-left.geteilt a {
            width: 100%;
        }

        .flex-big-right.geteilt a {
            flex: 2;
        }

    }

}



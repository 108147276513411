/*! --Breakpoints-- */
@include mq($mobile_s) {
   
}

@include mq($mobile) {
    
    @include rsce_nav-mobile;
    @include rsce_partner-mobile;
    @include rsce_footer-mobile;
    
}

@include mq($mobile_l) {
    
	@include rsce_produkte-mobile_l;
}

@include mq($tablet_s) {
	@include heading_tablet;
}

@include mq($tablet) {
    @include ce_text-tablet;
	@include rsce_produktvorstellung-tablet;
    @include rsce_slideshow-tablet;
    @include rsce_gallerie-tablet;
    @include rsce_gallerie_geteilt-tablet;
    @include rsce_referenzen-tablet;
    @include rsce_partner-tablet;
    @include rsce_footer-tablet;
}

@include mq($tablet_l) {
    @include rsce_produkte-tablet;
    @include rsce_slideshow-tablet_s;
    @include rsce_nav-tablet_l;
}

@include mq($desktop_s) {
   
    @include rsce_footer-desktop;
	
}

@include mq($desktop) {
    @include rsce_nav_desktop;

}

@include mq($desktop_l) {
	
}



.ce_rsce_partner .inside {
    text-align: center;

    h2 {color: $light;}

    .column {margin-bottom: 1.5rem;}
    p {max-width: 100%;text-align: center;margin: auto;}

    .partner-container {

        display: grid;
        grid-gap: 5rem;
        margin-top: 6.25rem;
        align-items: center;
        
        .partner {
            picture {display: flex;justify-content: center;}
            img {filter: grayscale(100);transition: 0.5s filter;width: 75%;}
            img:hover {filter: grayscale(0);}

        }

    }
}


@mixin rsce_partner-mobile{

    .ce_rsce_partner .inside .partner-container {
        grid-template-columns: 1fr 1fr;
    }

}

@mixin rsce_partner-tablet{
    
    .ce_rsce_partner .inside .partner-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .ce_rsce_partner .inside {
        p {max-width: 60%;}

    }

}